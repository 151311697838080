<template>
  <!--侧边一级导航-->
  <div>
      <el-menu
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      router
      style="height: 100%;"
      :default-active="activePath"
    >
      <div class="btnCollapse" @click="collapseStatus()">
        <i class="ydfont ydcaidan1"></i>
      </div>
      <el-menu-item index="/admin/examination/lists" @click="activeHandle1('/admin/examination/lists')">
        <i class="ydfont ydkaoshi"></i>
        <span slot="title">考试管理</span>
      </el-menu-item>
      <el-menu-item index="/admin/group/lists" @click="activeHandle2('/admin/group/lists')">
        <i class="ydfont ydjiqunjiankong"></i>
        <span slot="title">监控组管理</span>
      </el-menu-item>
      <el-menu-item index="/admin/staff/lists" @click="activeHandle2('/admin/staff/lists')">
        <i class="ydfont ydicon_jiankong-"></i>
        <span slot="title">监控人员管理</span>
      </el-menu-item>
      <el-menu-item index="/admin/examinee/lists" @click="activeHandle2('/admin/examinee/lists')">
        <i class="ydfont yddanganhe"></i>
        <span slot="title">考生信息管理</span>
      </el-menu-item>
        <el-menu-item index="/admin/examvideo/lists" @click="activeHandle2('/admin/examvideo/lists')">
          <i class="ydfont ydluzhivideo"></i>
          <span slot="title">在线监控管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/examvideopw/lists" @click="activeHandle2('/admin/examvideopw/lists')">
          <i class="ydfont ydluzhivideo"></i>
          <span slot="title">评委监控管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/videorecord/lists" @click="activeHandle2('/admin/videorecord/lists')">
          <i class="ydfont ydluzhivideo"></i>
          <span slot="title">录制视频管理</span>
        </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { loginUserInfo } from "r/admin/login";

export default {
  name: "Aside",
  props: {
    isCollapseFu: Boolean, // 是否水平折叠
  },
  data() {
    return {
      activePath: '', // 当前激活的分类
      isCollapse: this.isCollapseFu
    }
  },
  created() {
    this.activePath = window.sessionStorage.getItem('admin-activePath')
  },
  methods: {
    // 保存当前激活的分类
    activeHandle1 (active) {
      // 点击下一个一级菜单，删除上个菜单的二级菜单的 admin-activeMenu
      if(window.sessionStorage.getItem('admin-activePath') != active){
        window.sessionStorage.setItem('admin-activeMenu', 0)

        this.isCollapse = false
        this.$emit("changeStatus", this.isCollapse);
      }else{
         this.isCollapse = this.isCollapse;
         this.$emit("changeStatus", this.isCollapse);
      }

      window.sessionStorage.setItem('admin-activePath', active)
      this.activePath = active
    },

    // 保存当前激活的分类
    activeHandle2 (active) {
      // 点击下一个一级菜单，删除上个菜单的二级菜单的 admin-activeMenu
      if(window.sessionStorage.getItem('admin-activePath') != active){
        window.sessionStorage.setItem('admin-activeMenu', 0)
        this.isCollapse = false
        this.$emit("changeStatus", this.isCollapse);
      }else{
         this.isCollapse = this.isCollapse;
         this.$emit("changeStatus", this.isCollapse);
      }

      window.sessionStorage.setItem('admin-activePath', active)
      this.activePath = active
    },

    // 控制是否水平折叠收起菜单
    collapseStatus() {
      this.isCollapse = !this.isCollapse;
      this.$emit("changeStatus", this.isCollapse);
    },
  },
  watch: {
    isCollapse(){
      this.isCollapseFu = this.isCollapse;
    }
  }
};
</script>

<style scoped lang="scss">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  // min-height: auto;
  min-height: 340px;
  background-color: #2c303e;
}

.el-menu--collapse {
  width: 80px;
  background-color: #2c303e;
  .el-menu-item {
    padding: 0;
  }
}

.el-menu-item {
  margin: 10px;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  border-left: 4px solid transparent;

  font-family: "Source Han Sans CN";
  font-weight: 400;
  color: #a0a2a8;

  // 单行文本溢出隐藏显示省略号
  // white-space: nowrap;
  overflow: hidden;
  // text-overflow: ellipsis;

  span {
    margin-left: 10px;
  }
}

.el-menu-item:hover,
.el-menu-item:hover i,
.el-menu-item:hover span {
  color: #ffffff;
  background-color: #323748;
  border-radius: 4px;
}

.is-active {
  border-left: 4px solid #6e1c70;
  border-radius: 4px;
  color: #ffffff;
  background-color: #323748;
}

.btnCollapse{
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: #2c303e;
  i {
    font-size: 16px;
    color: #ffffff;
  }
}
</style>
