<template>
  <el-container class="container">
    <el-header class="b-header">
      <Header :userInfo="userInfo"></Header>
    </el-header>
    <el-container class="b-container">
      <el-aside :width="isCollapse ? '80px' : '14%'" :style="isCollapse ? 'transition: width .3s;' : ''">
        <Aside :isCollapseFu="isCollapse" style="height:100%;" @changeStatus="changeStatus" ></Aside>
      </el-aside>
      <el-container>
        <router-view style="height:100%" />
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
  import Header from "c/jiDong/Header";
  import Footer from "c/jiDong/Footer";
  import Aside from "c/jiDong/Aside";
  export default {
    name: "jiDong-index",
    components: {
      Header,
      Footer,
      Aside,
    },
    data() {
      return {
        isCollapse: false, // 是否水平折叠
        userInfo: JSON.parse(window.localStorage.getItem("jiDong-userInfo")), // 登录用户
      };
    },
    methods: {
      changeStatus(val) {
        this.isCollapse = val;
      },
    },
  };
</script>
<style scoped lang="scss">
  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-width: 1000px;

    .b-header {
      height: 60px;
      padding: 0;
      z-index: 1;
    }
    .b-container {
      height: 100%;
      overflow: hidden;
    }

    .el-container {
      height: 100%;
      overflow: hidden;
    }
  }
</style>
