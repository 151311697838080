<template>
    <!--头部-->
    <div class="header-box">
        <el-row>
            <el-col :span="13">
                <span class="h-title">Mirror</span>
            </el-col>
            <el-col :span="11" style="float:right;">
                <span class="h-user">{{ userInfo }}</span>
                <span class="logout" @click="logout()">退出</span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {logout} from "r/jiDong/login.js";

    export default {
        name: "Header",
        props: {
            userInfo: [String, Number],
        },
        methods: {

            // 退出登录
            async logout() {
                const confirmResult = await this.$confirm(`确认退出？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    closeOnClickModal: false,
                }).catch((err) => err);

                if (confirmResult !== "confirm") {
                    return this.$message.info("您取消了退出");
                }

                const {data: res} = await logout({
                    userName: this.userInfo
                });
                setTimeout(()=>{
                    // 清除本地的 token
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    // 使用编程式导航跳转到登录页面
                    this.$router.push("/jiDong/login");
                }, 1000)
            },
        },
    };
</script>

<style scoped lang="scss">
    .header-box {
        @extend .bt-box-shadow;
        z-index: 1;
        height: 60px;
        line-height: 60px;
        background-color: #ffffff;

        .el-col-13 {
            .h-title {
                color: #333333;
                font-size: 24px;
                font-family: 'Times New Roman';
                font-weight: 400;
                margin-left: 5%;
            }
        }

        .el-col-11 {
            color: rgba(51, 51, 51, 0.6);
            font-size: 14px;
            line-height: 60px;
            font-weight: 400;
            text-align: right;

            .h-user {
                font-family: "Arial";
                font-weight: 400;
            }

            .logout {
                color: rgba(51, 51, 51, 0.6);
                font-family: "Source Han Sans CN";
                cursor: pointer;
                margin: 0 50px 0 10px;

                &:hover {
                    color: rgba(86, 50, 122, 1);
                }
            }
        }
    }
</style>
