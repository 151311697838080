<template>
  <el-container class="container">
    <el-header class="b-header">
      <Header :userInfo="userInfo"></Header>
    </el-header>
    <el-container class="b-container">
      <el-aside :width="isCollapse ? '80px' : '14%'" :style="isCollapse ? 'transition: width .3s;' : ''">
        <Aside :isCollapseFu="isCollapse" style="height:100%;" @changeStatus="changeStatus" ></Aside>
      </el-aside>
      <el-container>
        <el-main>
          <el-aside><AsideSecond></AsideSecond></el-aside>
          <router-view style="height:100%"  />
        </el-main>
      </el-container>
    </el-container>
    <!-- <el-footer><Footer></Footer></el-footer> -->
  </el-container>
</template>
<script>
import Header from "c/admin/Header";
import Footer from "c/admin/Footer";
import AsideSecond from "c/admin/AsideSecond";
import Aside from "c/admin/Aside";
export default {
  name: "admin-index",
  components: {
    Header,
    Footer,
    Aside,
    AsideSecond,
  },
  data() {
    return {
      isCollapse: false, // 是否水平折叠
      userInfo: JSON.parse(window.localStorage.getItem("admin-userInfo")), // 登录用户
    };
  },
  methods: {
    changeStatus(val) {
      this.isCollapse = val;
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1000px;
  // display: flex;
  // flex-flow: column;

  .b-header {
    height: 60px;
    padding: 0;
    z-index: 1;
  }
  .b-container {
    height: 100%;
    overflow: hidden;
    }
    
    .el-container {
      // min-width: 1100px;
      height: 100%;
      overflow: hidden;
      // .el-main{
      //   padding: 20px 20px 20px 0;
      //   margin-left: 20px;
      // }
  }
}
</style>
