<template>
    <!--侧边一级导航-->
    <div>
        <el-menu
                class="el-menu-vertical-demo"
                :collapse="isCollapse"
                router
                style="height: 100%;"
                :default-active="activePath"
        >
            <div class="btnCollapse" @click="collapseStatus()">
                <i class="ydfont ydcaidan1"></i>
            </div>
            <template v-for="item in menuList">
                <el-menu-item
                        :index="'/jiDong/'+item.path"
                        v-if="item.meta.roleId.includes(Number(roleId))"
                        @click="activeHandle('/jiDong/'+item.path)">
                    <i :class="item.meta.icon"></i>
                    <span slot="title">{{item.meta.title}}</span>
                </el-menu-item>
            </template>

        </el-menu>
    </div>
</template>

<script>
    import routeList from "router/jiDong/index";
    export default {
        name: "Aside",
        props: {
            isCollapseFu: Boolean, // 是否水平折叠
        },
        data() {
            return {
                activePath: '', // 当前激活的分类
                isCollapse: this.isCollapseFu,
                roleId: null,
                menuList: [],
            }
        },
        created() {
            this.activePath = window.sessionStorage.getItem('jiDong-activePath')
            this.roleId = window.localStorage.getItem('jiDong-roleId')
            this.menuList = routeList;
        },
        methods: {
            // 保存当前激活的分类
            activeHandle(active) {
                // 点击下一个一级菜单，删除上个菜单的二级菜单的 jiDong-activeMenu
                if (window.sessionStorage.getItem('jiDong-activePath') != active) {
                    window.sessionStorage.setItem('jiDong-activeMenu', 0)

                    this.isCollapse = false
                    this.$emit("changeStatus", this.isCollapse);
                } else {
                    this.isCollapse = this.isCollapse;
                    this.$emit("changeStatus", this.isCollapse);
                }

                window.sessionStorage.setItem('jiDong-activePath', active)
                this.activePath = active
            },

            // 保存当前激活的分类
            // activeHandle2 (active) {
            //   // 点击下一个一级菜单，删除上个菜单的二级菜单的 jiDong-activeMenu
            //   if(window.sessionStorage.getItem('jiDong-activePath') != active){
            //     window.sessionStorage.setItem('jiDong-activeMenu', 0)
            //     this.isCollapse = false
            //     this.$emit("changeStatus", this.isCollapse);
            //   }else{
            //      this.isCollapse = this.isCollapse;
            //      this.$emit("changeStatus", this.isCollapse);
            //   }
            //
            //   window.sessionStorage.setItem('jiDong-activePath', active)
            //   this.activePath = active
            // },

            // 控制是否水平折叠收起菜单
            collapseStatus() {
                this.isCollapse = !this.isCollapse;
                this.$emit("changeStatus", this.isCollapse);
            },
        },
        watch: {
            isCollapse() {
                this.isCollapseFu = this.isCollapse;
            }
        }
    };
</script>

<style scoped lang="scss">
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 100%;
        // min-height: auto;
        min-height: 340px;
        background-color: #2c303e;
    }

    .el-menu--collapse {
        width: 80px;
        background-color: #2c303e;

        .el-menu-item {
            padding: 0;
        }
    }

    .el-menu-item {
        margin: 10px;
        font-size: 14px;
        height: 60px;
        line-height: 60px;
        border-left: 4px solid transparent;

        font-family: "Source Han Sans CN";
        font-weight: 400;
        color: #a0a2a8;

        // 单行文本溢出隐藏显示省略号
        // white-space: nowrap;
        overflow: hidden;
        // text-overflow: ellipsis;

        span {
            margin-left: 10px;
        }
    }

    .el-menu-item:hover,
    .el-menu-item:hover i,
    .el-menu-item:hover span {
        color: #ffffff;
        background-color: #323748;
        border-radius: 4px;
    }

    .is-active {
        border-left: 4px solid #6e1c70;
        border-radius: 4px;
        color: #ffffff;
        background-color: #323748;
    }

    .btnCollapse {
        text-align: center;
        height: 40px;
        line-height: 40px;
        background-color: #2c303e;

        i {
            font-size: 16px;
            color: #ffffff;
        }
    }
</style>
